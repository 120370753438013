<template>
    <div class="Awarp">
        <dashCard class="powerBox15" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">重载分析</template>
            <template slot="aside">
                <div class="type-group">
                    <span class="pick-type" v-for="(item,idx) in timetType" :key="idx" @click="changeType(item.code)">
                        <font :class="{'isactive-type': item.code == searchObj.TYPE}">{{ item.name }}</font>
                    </span>
                </div>
                <a-date-picker v-if="searchObj.TYPE=='按天'" :placeholder="commonLangHandler('chuneng7_day','选天', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"/>
                <a-week-picker v-if="searchObj.TYPE=='按周'" :placeholder="commonLangHandler('powerBox28_selectWeek','选周', getZEdata)"  v-model="searchObj.VALUE" @change="onChange" />
                <a-month-picker v-if="searchObj.TYPE=='按月'" :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)" v-model="searchObj.VALUE" @change="onChange" />
                <a-date-picker v-if="searchObj.TYPE=='按年'" :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"  v-model="searchObj.VALUE" @change="onChange"
                mode="year"
                :open="panelOpen"
                @openChange="openChange"
                @panelChange="panelChange"
                format="YYYY"
                />
            </template>
            <div class="card-content1">
                    <a-select v-model="searchObj['ITEM']" style="width: 256px" @change="handleChange" :getPopupContainer="triggerNode => triggerNode.parentNode">
                        <a-select-option v-for="(item,key) in itemList" :key="key" :value="item.code">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>

                <div class="card-content2" >
                    <chartBoard ref="chart1" :option="chartOption1"/>
                </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <powerBox15  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    export default {
        name: 'powerBox15',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
            chartBoard: () => import('@/component/chartBoard.vue'),
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                } 
            }
        },
        watch: {
            gridOption: {
                handler: function(newVal,oldVal) {
                    this.$nextTick(_=>{
                        this.getOperateWidth()
                        var dom = this.$refs['chart1']
                        if(!!dom) {
                            dom.handleWindowResize()
                        }
                    })
                },
                deep: true,
                immediate: true
            },
            "option.visible": {//全屏化时执行
                handler: function(newVal,oldVal) {
                    if(newVal) {
                        if(this.isModal) {
                            this.searchObj = this.orginSearchObj
                            this.getData()
                        }
                    }
                },
                deep: true,
                immediate: true
            },
        },
        data() {
            return {
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                timetType: [
                {
                    code: '按天',
                    name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
                },
                {
                    code: '按周',
                    name:'按周'
                },
                {
                    code: '按月',
                    name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
                },
                {
                    code: '按年',
                    name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
                }
            ],
            itemList: [
                {
                    code: '1AT1_进线柜',
                    name:'1AT1_进线柜'
                },
                {
                    code: '1AT2_进线柜',
                    name:'1AT2_进线柜'
                },
                {
                    code: '1AT3_进线柜',
                    name:'1AT3_进线柜'
                },
               
            ],
            panelOpen: false,
            searchObj: {
                TYPE: '按天',//按天,按周,按月,按年
                VALUE: this.$moment(),//日期
                ITEM: '1AT1_进线柜',//选中的项目
            },
            chartOption1: {},
            detailInfo: {
                chart1: {}
            },
            colorList: ['#3366FF', '#CB2BD5', '#11A826'],
            }
        },
        computed: {},
        methods: {
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width  
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

 
       // 改变类型
       changeType(type) {
            this.searchObj.TYPE = type
            this.getData()
        },
	    openChange(status) {
            this.panelOpen = status
	    },
        // 年份弹窗关闭
	    panelChange(value){
	      this.searchObj.VALUE = value;
	      this.panelOpen = false;
          this.getData()
	    },
        onChange(value) {
            console.log("onChange",value,this.searchObj)
            this.getData()
            this.$forceUpdate()
        },
        // 切换项目
        handleChange(value) {
            this.getData()
        },
        //每月报警预警数
        getData() {
            this.isLoading = true
            this.detailInfo = {}
            var chart1 = {
                categoryData: ['8:01','8:02','8:03','8:04','8:05','8:06','8:07','8:08','8:09','8:10','8:11','8:12'],
                seriesData: [
                    {
                        name: "1AT1_进线柜",
                        value: [880,  800, 800, 750,900, 950, 820, 990, 820, 890, 800, 720],
                    },
                ]
            }
            this.detailInfo.chart1 = chart1
            this.initEchart()
            this.isLoading = false
        },
		initEchart() {
            var dom = this.$refs['chart1']
            setTimeout(() => {
                if(!!dom) {
                    dom.handleWindowResize()
                }
            }, 30)
            var {categoryData,seriesData} = this.detailInfo.chart1
            var series = []
            seriesData.forEach(item=>{
                var obj = {
                    type: 'line',
                    smooth: false,
                    name: item.name,
                    data: item.value,
                    barGap:'0%',
                }
                series.push(obj)
            })

            series[0].markLine= {
                silent: true,
                lineStyle: {
                    normal: {
                        color: 'rgba(134,144,156,1)'                   // 这儿设置安全基线颜色
                    }
                },
                data: [{
                    yAxis: 900
                }],
                label: {
                    
                normal: {
                    position: 'start',
                    formatter: '提醒'           // 这儿设置安全基线
                }
                },
                symbol: 'none',//去掉箭头
            }

            var option = {
                animation:false,
                layoutAnimation:false,
                grid: {
                    left: '1%',
                    right: '0%',
                    bottom: '7%',
                    top: '15%',
                    containLabel: true
                },
                visualMap: {
                    show: false,
                    seriesIndex: 0,
                    pieces: [
                    {
                        gt: 0,
                        lte: 900,          //这儿设置基线上下颜色区分 基线下面为绿色
                        // color: '#03d6d6'
                    }, 
                    {
                                
                        gt: 780,          //这儿设置基线上下颜色区分 基线上面为红色
                        color: '#e91642'
                    }]
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                    type: 'shadow',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                    }
                },
                dataZoom: [
                    {
                    type: 'inside',
                    start: 0,
                    end: 100,
                    height: 20,
                    bottom: 0,
                    },
                    {
                    start: 0,
                    end: 100,
                    height: 20,
                    bottom: 10,
                    }
                ],
                xAxis: {
                    data: categoryData,
                    axisLine: {
                        lineStyle: {
                            color: '#1f1f1f'
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#696969',
                        fontSize: 14
                    },
                },
                yAxis: {
                    name: "单位（kWh）",
                    axisTick: {
                        show: false
                    },
                    nameTextStyle: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#9f9f9f',
                        fontSize: 14
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#1f1f1f'
                        }
                    },
                    axisLabel: {
                        fontFamily: 'ABBvoice_WCNSG_Rg',
                        color: '#696969',
                        fontSize: 14
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            // type: "dashed",
                            width: 0.5,
                            color: '#bababa'
                        }
                    },
                    // 控制数据参数最大值和最小值
                    interval: 200,
                    max: 1000

                },
                // 控住柱状图样式
                series:series,
                color: this.colorList
            };
            
            this.updateChart('chart1', 'chartOption1', option)
        },
        updateChart: function (refName, optionName, option = {}) {
            /* 渲染echart图 */
            if (!optionName) return
            this[optionName] = option
            setTimeout(() => {
                this.$refs[refName].updateChartView()
            }, 500)
        }, 
            
        },
        mounted() {
            this.getOperateWidth()
            if(!this.option.visible) {
                this.getData()
            }
        }
    }
    </script>
    
<style lang="less" scoped>
.powerBox15 {
    .card-content1 {

    }
    .card-content2 {
        flex: 1;
        height: 100%;
        div{
            width: 100%;
            height: 100%;
        }
    }
}
</style>